import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Decoration = makeShortcode("Decoration");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const Row = makeShortcode("Row");
const Paragraph = makeShortcode("Paragraph");
const SharePage = makeShortcode("SharePage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnimateIntroBanner id="banner" className="background_image" background={<><RowLines mdxType="RowLines" /><Decoration from="/blog/risk-management-case-study/01.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
  <Heading mdxType="Heading">Risk Management Case Study</Heading>
  <LearnMore elementId="description" color="white" mdxType="LearnMore" />
  </Block>
    </AnimateIntroBanner>
    <Row id="description" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <Block mdxType="Block">
  <Heading level={3} className="no_animation" mdxType="Heading">1st September 1990</Heading>
  <Paragraph mdxType="Paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ornare tellus et tristique accumsan. Maecenas at magna nisi. Donec tempor mauris suscipit tortor facilisis, eget rutrum eros hendrerit. Integer aliquet ullamcorper libero a finibus.</Paragraph>
  </Block>
    </Row>
    <Row className="gallery" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <Block mdxType="Block">
    <Decoration from="/blog/risk-management-case-study/02.jpg" mdxType="Decoration" />
  </Block>
    </Row>
    <Row className="blog_content" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <Block mdxType="Block">
  <Paragraph mdxType="Paragraph">Aliquam eu sapien justo. Curabitur augue velit, aliquam dignissim hendrerit vel, laoreet eget est. Quisque id mattis orci. Proin lobortis consequat turpis vitae egestas. Morbi in vulputate neque. Mauris hendrerit neque laoreet quam condimentum, sed lobortis ex sagittis. Praesent ut sapien nec erat tincidunt auctor. Aenean dictum condimentum urna et efficitur. Vivamus ac sem commodo, scelerisque metus quis, maximus orci.</Paragraph>
  <Paragraph mdxType="Paragraph">Suspendisse ut ultrices erat, id tempor nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nunc posuere elit et dolor cursus porta. Aenean porttitor efficitur mauris, quis eleifend lorem lacinia in. Praesent dignissim ultricies erat, laoreet aliquet augue sodales sit amet. </Paragraph>
  <Paragraph mdxType="Paragraph">Aliquam eu sapien justo. Curabitur augue velit, aliquam dignissim hendrerit vel, laoreet eget est. Quisque id mattis orci. Proin lobortis consequat turpis vitae egestas. Morbi in vulputate neque. Mauris hendrerit neque laoreet quam condimentum, sed lobortis ex sagittis. Praesent ut sapien nec erat tincidunt auctor. Aenean dictum condimentum urna et efficitur. Vivamus ac sem commodo, scelerisque metus quis, maximus orci.</Paragraph>
  </Block>
    </Row>
    <Row className="gallery gallery-2" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <Block mdxType="Block">
    <Decoration from="/blog/risk-management-case-study/03.jpg" mdxType="Decoration" />
    <Decoration from="/blog/risk-management-case-study/04.jpg" mdxType="Decoration" />
  </Block>
    </Row>
    <Row id="share" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <Block mdxType="Block">
  <SharePage mdxType="SharePage" />
  </Block>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      